/* You can add global styles to this file, and also import other style files */

body {
  margin: 0;
}

.success-snackbar {
  background: rgb(0, 134, 18);
  color: #fff;
}

.error-snackbar {
  background: rgb(153, 0, 0);
  color: #fff;
}

li {
  outline: none;
}

div {
  outline: none;
}

.__icon {
  font-size: 20px;
}

.__icon_size {
  font-size: 20px;
}

.__row {
  cursor: pointer;
}

.inner-content {
  padding: 12px;
  background: #fff;
  margin-bottom: 12px;
  min-height: 100%;
}

.__upper_case {
  text-transform: uppercase;
}
